<template>
  <v-main>
    <v-container
      fluid
      class="pa-0"
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="6"
          lg="3"
        >
          <v-sheet
            color="primary"
            dark
            class="pt-16"
          >
            <v-row justify="center">
              <Logo
                class="cursor--pointer"
                width="200px"
                @click="handleLogoClick"
              />
            </v-row>
            <v-row>
              <router-view />
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
  name: 'Authorisation',
  components: { Logo },
  methods: {
    handleLogoClick() {
      if (this.$route.name === 'login') return;
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
