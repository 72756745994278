<template>
  <router-link
    :to="{ name: 'dashboard' }"
    class="logo-wrapper ma-2"
  >
    <VImg
      v-if="logo"
      v-bind="$attrs"
      :src="`${logo}&type=small`"
      alt="Logo"
    />
  </router-link>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Logo',
  computed: {
    ...mapState('environment', {
      logo: 'logo',
    }),
  },
};
</script>

<style scoped>
.logo-wrapper {
  display: inline-flex;
}
</style>
